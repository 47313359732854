<template>
  <div>
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>

    <div class="d-flex align-center justify-space-around">
      <div class="form-content text-center" style="width:100% !important">
        <div v-if="data.length != 0" class="d-flex align-center">
          <div class="flex-grow-1">
            <v-text-field
              dense
              hide-details
              v-model="search"
              :label="'Search ' + data.length + ' rows'"
              outlined
              clearable
              append-icon="mdi-magnify"
            ></v-text-field>
          </div>
          <!-- <div class="px-2 flex-grow-0">
            <v-btn fab small color="primary">
              <v-icon dark> mdi-tune </v-icon>
            </v-btn>
          </div> -->
        </div>
        <div v-if="data.length != 0" class="text-right my-3 text-caption grey--text">
          Showing {{ page * 10 - 9 }} to
          {{ page == totalPage ? data.length : page * 10 }} of
          {{ data.length }}
        </div>

        <v-data-table
          :loading="api.isLoading"
          :headers="headers"
          :items="data"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-0 w-100"
          :search="search"
          @page-count="pageCount = $event"
          @click:row="handleClick"
          mobile-breakpoint="0"
        >
          <template v-slot:item.index="{ item, index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:item.avatar="{ item }">
            <AAvatar
              v-if="getAvatar(item)"
              :width="40"
              :height="40"
              :avatar="getAvatar(item).avatar"
            />
            <v-img
              v-else
              :max-height="40"
              :max-width="40"
              :src="require('@/assets/CS0040_default_F0.png')"
            ></v-img>
          </template>
          <template v-slot:item.studentAvatar="{ item }">
            <AAvatar
              v-if="getAvatar(item.Student)"
              :width="40"
              :height="40"
              :avatar="getAvatar(item.Student).avatar"
            />
            <v-img
              v-else
              :max-height="40"
              :max-width="40"
              :src="require('@/assets/CS0040_default_F0.png')"
            ></v-img>
          </template>
          <template v-slot:item.button="{ item }">
            <div @click="handleClick(item)">
              <v-icon>mdi-chevron-right</v-icon>
            </div>
          </template>
          <template v-slot:item.eventAction="{ item }">
            <div v-if="
              $moment() >= $moment(item.timestampStart) &&
              $moment() < $moment(item.timestampEnd)
              " @click="handleClick(item)">
              <v-icon>mdi-chevron-right</v-icon>
            </div>
            <div v-else-if="
              $moment() > $moment(item.timestampEnd)
              " >
              <v-btn @click="handleClick(item)" color="primary" text>View Finishers</v-btn>
              
            </div>
            <div v-else><v-icon>mdi-lock</v-icon></div>
          </template>
          <template v-slot:item.eventDate="{ item }">
            <EventDate :data="item"></EventDate>
          </template>
          <template v-slot:item.dateCreated="{ item }">
        <span>{{ $moment(item.timestampCreated).format("YYYY-MM-DD") }}</span>
      </template>
          <template v-slot:item.eventGamePoster="{ item }">
            <EventGamePoster :data="item"></EventGamePoster>
          </template>
          <template v-slot:item.sponsoredStudentstatus="{ item }">
            <v-chip
          v-if="item.status == 'APPROVED' && $moment(item.timestampEnd) >= $moment()"
          small
          dark
          color="green"
          >Approved</v-chip
        >
        <v-chip
          v-else-if="item.status == 'APPROVED' && $moment(item.timestampEnd) < $moment()"
          small
          dark
          color="gray"
          >Expired</v-chip
        >
        <v-chip
          v-else-if="item.status == 'UNDER_REVIEW'"
          small
          dark
          color="orange"
          >Under Review</v-chip
        >
        <v-chip
          v-else-if="item.status == 'IN_QUERY'"
          small
          dark
          color="blue"
          >In Query</v-chip
        >
        <v-chip
          v-else-if="item.status == 'CANCELED'"
          small
          dark
          color="black"
          >Terminated</v-chip
        >
        <v-chip v-else small dark color="red">Rejected</v-chip>
      </template>
          <template v-slot:item.eventStatus="{ item }">
            <EventStatus :data="item"></EventStatus>
          </template>
          <template v-slot:item.countStudent="{ item }">
            <div>{{ item._count.Student }}</div>
          </template>
          <template v-slot:item.schoolYear="{ item }">
            <div v-if="item.schoolYear">
              {{ $_.find(datasetSchoolYears, { id: item.schoolYear }).name }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:item.isActive="{ item }">
            <!-- <ColumnBoolean
              :value="item.isActive"
              :textTrue="$t('model.value.status.active')"
              :textFalse="$t('model.value.status.inactive')"
            ></ColumnBoolean> -->
            <ColumnBoolean
              :value="item.isActive"
              textTrue="Active"
              textFalse="Inactive"
            ></ColumnBoolean>
          </template>
          <template v-slot:item.isTransferred="{ item }">
            <ColumnBoolean
              :value="item.isTransferred"
            ></ColumnBoolean>
          </template>
          <template v-slot:item.classroomName="{ item }">
            <div>
              {{ item.Classroom.name }}
            </div>
          </template>
          <template v-slot:item.userAccountMobile="{ item }">
            <div>{{item.User.Account.mobile}}</div>
          </template>
          <template v-slot:item.userName="{ item }">
            <div>
              {{ item.User.name
              }}<span v-if="item.User.accountId == item.Institution.accountId" class="pl-2"
                ><v-chip color="black" dark x-small>Admin</v-chip></span
              ><!-- <span v-if="item.User.Account.InvitedAccount[0] && !item.User.Account.InvitedAccount[0].timestampPasswordChanged" class="pl-2"
                ><v-chip x-small>Pending Invite</v-chip></span
              > -->
            </div>
          </template>
          <template v-slot:item.timestampStart="{ item }">
        <span>{{
          $moment(item.timestampStart).format("DD MMM YYYY hh:mm A")
        }}</span>
      </template>
      <template v-slot:item.timestampEnd="{ item }">
        <span>{{
          $moment(item.timestampEnd).format("DD MMM YYYY hh:mm A")
        }}</span>
      </template>
          <template v-slot:item.positionName="{ item }">
        <span v-if="item.Position && item.Position.translatableName">{{
          JSON.parse(item.Position.translatableName)[$_getLocale()]
        }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.sponsoredStudentAppliedBy="{ item }">
        <span v-if="item.User">{{
         item.User.name
        }}</span>
        <span v-else>Moderator</span>
      </template>
          <template v-slot:item.joinedStatus="{ item }">
            <span v-if="(item.User.Account.InvitedAccount[0] && item.User.Account.InvitedAccount[0].timestampPasswordChanged)">{{$moment(item.User.Account.InvitedAccount[0].timestampPasswordChanged).format("DD MMM YYYY hh:mm A")}}</span>
            <span v-else-if="!item.User.Account.InvitedAccount[0]">{{$moment(item.timestampCreated).format("DD MMM YYYY hh:mm A")}}</span>
            <span v-else><v-chip x-small>Pending Invite</v-chip></span>
          </template>
        </v-data-table>
        <div v-if="data.length != 0" class="d-flex justify-center align-center pt-3">
          <div class="px-1">
            <v-btn
              @click="goFirstPage()"
              text
              small
              color="black"
              :disabled="page == 1"
            >
              <v-icon dark> mdi-page-first </v-icon>
            </v-btn>
          </div>
          <div class="px-1">
            <v-btn
              @click="goPreviousPage()"
              text
              :disabled="page == 1"
              small
              color="black"
            >
              <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>
          </div>
          <div class="px-1">
            <v-menu rounded="lg" offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  color="black"
                  rounded
                  class="d-none d-sm-flex"
                  v-bind="attrs"
                  v-on="on"
                  width="120px"
                  text
                >
                  Page {{ page }}
                  <v-icon right dark> mdi-menu-down </v-icon>
                </v-btn>
                <v-btn
                  fab
                  v-bind="attrs"
                  class="d-flex d-sm-none"
                  v-on="on"
                  small
                  color="black"
                >
                  {{ page }}
                </v-btn>
              </template>
              <v-list style="max-height: 200px" class="overflow-y-auto">
                <v-list-item
                  v-for="p in totalPage"
                  :key="p"
                  link
                  @click="selectPage(p)"
                >
                  <v-list-item-title>Page {{ p }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="px-1">
            <v-btn
              @click="goNextPage()"
              text
              :disabled="page == totalPage"
              small
              color="black"
            >
              <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
          <div class="px-1">
            <v-btn
              :disabled="page == totalPage"
              @click="goLastPage()"
              text
              small
              color="black"
            >
              <v-icon dark> mdi-page-last </v-icon>
            </v-btn>
          </div>
          <!-- <v-pagination v-model="page" :length="pageCount"  :total-visible="7"></v-pagination> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import datasetSchoolYears from "@/datasets/schoolYear";
import ColumnBoolean from "./ColumnBoolean.vue";
import EventDate from "@/components/Event/EventDate";
import EventGamePoster from "@/components/Event/EventGamePoster";
import EventStatus from "@/components/Event/EventStatus";
export default {
  components: {
    ColumnBoolean,
    EventDate,
    EventGamePoster,
    EventStatus,
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["url", "headers", "callbackClick"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    totalPage: 0,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    players: [],
    // headers: [
    //   {
    //     text: "#",
    //     align: "start",
    //     sortable: false,
    //     value: "index",
    //   },
    //   { text: "Name", value: "name" },
    //   { text: "", value: "button", align: "end" },
    // ],
    data: [],
    //
    datasetSchoolYears: datasetSchoolYears,
  }),
  created() {
    this.api.method = "GET";
    this.api.url = this.url;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.data = resp;
      this.totalPage = Math.ceil(this.data.length / 10);
      this.playerApi.params = {mochiIds:this.$_.compact(this.$_.map(this.data,'mochiId'))}
    this.$api.fetch(this.playerApi);
    };
    this.playerApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/player/getByMochiIds";

    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = true;
      this.playerApi.isError = false;
    };

    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
    };

    this.playerApi.callbackSuccess = (resp) => {
      this.playerApi.isLoading = false;
      this.api.isLoading = false;
      this.players = resp;
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    handleClick(item) {
      this.callbackClick(item);
    },
    getAvatar(user) {
      console.log(user)
      let avatar = null;
      if (user.mochiId) {
        console.log(user.mochiId)
        avatar = this.$_.find(this.players, function (n) {
          if (n.mochiId == user.mochiId) {
            return n.avatar;
          }
        });
      }
      console.log(avatar)
      return avatar;
    },
    goNextPage() {
      this.page++;
    },
    goPreviousPage() {
      this.page--;
    },
    selectPage(page) {
      this.page = page;
    },
    goLastPage() {
      this.page = this.totalPage;
    },
    goFirstPage() {
      this.page = 1;
    },
    toRegister() {
      this.$router.push({ name: "PageTestRegister" });
    },
    clickSocial() {
      this.isClickSocial = true;
      this.step = 3;
    },
    decrease() {
      this.step--;
    },
  },
};
</script>